<template>
  <div class="rules-setting" :class="[{'p-2 vx-card main-box': $route.name === 'rulesSetting'}]">
    <div class="rules-setting-fields">

      <custom-validate-textarea :label="$t('setting.rules.labels.rules')"
                                align="right"
                                rtl
                                :classes="{'w-full': true}"
                                v-model="club.rules"/>
    </div>
    <button v-show="false" id="editRulesSetting" @click="editRules"></button>
  </div>
</template>

<script>
import CustomValidateTextarea from "@/components/customInput/customValidateTextarea.vue";
import {editAppSetting, getSetting} from "@/http/requests/settings/setting";

export default {
  name: "rulesSetting",
  components: {CustomValidateTextarea},
  data () {
    return {
      club: {
        rules: {
          value: '',
          isValid: true
        }
      },
      actions: {
        toolbar: [
          {
            id: 'editRulesSetting',
            icon: 'SAVE',
            iconPack: 'useral',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'Settings'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created() {
    if (this.$route.name === 'Settings') {
      this.actions.leftToolbar.splice(0, 1)
    }
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })
    this.$store.dispatch('setPageTitle', this.$t('setting.rules.title'))
    this.getSetting()
  },
  methods: {
    getSetting() {
      getSetting().then((response) => {
        const app = response.data.data.app
        this.club.rules.value = app.rules || ''
      })
    },
    editRules() {
      if (this.club.rules.isValid) {
        const payload = {
          rules: this.club.rules.value
        }
        editAppSetting(payload).then(response => {
          this.$vs.notify({
            time: 2400,
            title: this.$t('alert.message.title'),
            text: this.$t('setting.notifications.editRules.success'),
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })

          if (this.$route.name === 'rulesSetting') {
            this.$router.push({name: 'Settings'})
          } else {
            this.$emit('edited')
          }
        }).catch(error => {
          this.$vs.notify({
            time: 2400,
            title: this.$t('alert.error.title'),
            text: error.response.data.message || error.response.data.error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
      }
    }
  }
}
</script>

<style scoped>

</style>