<template>
  <vx-card class="setting-menu-page main-box top-zero-radius">
    <profile-main-box :profile="getCompanyProfile"
                      :default-action="profileStatus"
                      :actions-list="actionsList"
                      @clubInfoSetting="handleChangeProfileStatus('clubInfoSetting')"
                      @treasurySetting="handleChangeProfileStatus('treasurySetting')"
                      @financialYearSetting="handleChangeProfileStatus('financialYearSetting')"
                      @financialSetting="handleChangeProfileStatus('financialSetting')"
                      @languagesSetting="handleChangeProfileStatus('languagesSetting')"
                      @faqSetting="handleChangeProfileStatus('faqSetting')"
                      @rulesSetting="handleChangeProfileStatus('rulesSetting')"
    >
      <club-info-setting v-if="profileStatus === 'clubInfoSetting'" @edited="getSetting"/>

      <treasury-settings v-if="profileStatus === 'treasurySetting'"/>

      <financial-year-list v-if="profileStatus === 'financialYearSetting'" in-modal/>

      <financial-setting v-if="profileStatus === 'financialSetting'"/>

      <locale-setting v-if="profileStatus === 'languagesSetting'" />

      <faq-list v-if="profileStatus === 'faqSetting'"/>

      <rules-setting v-if="profileStatus === 'rulesSetting'"/>
    </profile-main-box>

    <vs-button v-show="false"
               id="editClubInfo"
               @click="handleChangeProfileStatus('clubInfoSetting')"/>
  </vx-card>
</template>

<script>
  import vueMarkdown from 'vue-markdown'
  import {getSetting, editSetting, editAppSetting} from '../../../../http/requests/settings/setting'
  import {getAvatarUrl} from "../../../../assets/js/functions"
  import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import FaqList from "../faq/list/faqList";
  import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
  import AddressSetting from "../address/addressSetting";
  import EmployeeList from "../employee/list/employeeList";
  import TimesList from "../../club/times/list/timesList";
  import TelSetting from "../tel/telSetting";
  import MessageSetting from "../message/messageSetting";
  import TreasurySettings from "../treasury/TreasurySettings";
  import ClubInfoSetting from "../clubInfo/clubInfoSetting.vue";
  import FinancialSetting from "@/views/admin/settings/financial/financialSetting.vue";
  import {mapGetters} from "vuex";
  import FinancialYearList from "@/views/admin/settings/financialYear/list/financialYearList.vue";
  import LocaleSetting from "@/views/admin/settings/locale/LocaleSetting.vue";
  import RulesSetting from "@/views/admin/settings/rules/rulesSetting.vue";

  export default {
    name: 'userProfile',
    components: {
      RulesSetting,
      LocaleSetting,
      FinancialYearList,
      FinancialSetting,
      ClubInfoSetting,
      TreasurySettings,
      MessageSetting,
      TelSetting,
      TimesList,
      EmployeeList, AddressSetting, CustomValidateTextarea, FaqList, CustomIcon, ProfileMainBox, vueMarkdown
    },
    metaInfo () {
      return {
        title: this.$t('setting.title')
      }
    },
    data() {
      return {
        profileStatus: '',
        actions: {
          toolbar: [
            {
              id: 'editClubInfo',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning'
            }
          ]
        },
        club: {
          name: '',
          avatar: '',
          faq: [],
          rules: {
            value: '',
            isValid: true
          },
          supportNumber: '',
          whatsAppNumber: '',
          address: ''
        },
        actionsList: [
          {
            event: 'clubInfoSetting',
            name: 'clubInfoSetting',
            header: '',
            i18n: 'setting.actions.edit',
            icon: 'EDIT',
            iconPack: 'useral'
          },
          {
            event: 'treasurySetting',
            name: 'treasurySetting',
            header: '',
            i18n: 'setting.actions.treasury',
            icon: 'TREASURY_SETTING',
            iconPack: 'useral'
          },
          {
            event: 'financialYearSetting',
            name: 'financialYearSetting',
            header: '',
            permission: 'financial_year.show',
            i18n: 'setting.financialYear.title',
            icon: 'FINANCIAL_YEAR',
            iconPack: 'useral'
          },
          {
            event: 'financialSetting',
            name: 'financialSetting',
            header: '',
            // permission: 'financial_year.show',
            i18n: 'setting.financial.title',
            icon: 'DOLLAR_SIGN',
            iconPack: 'useral'
          },
          {
            event: 'languagesSetting',
            name: 'languagesSetting',
            header: '',
            i18n: 'setting.actions.language',
            icon: 'LANG_SETTING',
            iconPack: 'useral',
            permission: 'setting.locale.show'
          },
          {
            event: 'faqSetting',
            name: 'faqSetting',
            header: '',
            i18n: 'setting.actions.faq',
            icon: 'FAQ',
            iconPack: 'useral'
          },
          {
            event: 'rulesSetting',
            name: 'rulesSetting',
            header: '',
            i18n: 'setting.actions.rules',
            icon: 'SHIELD',
            iconPack: 'useral'
          },
        ]
      }
    },
    created() {
      if (this.isMobile) {
        this.actions.toolbar[0].id = {name: 'clubInfoSetting'}
        this.actions.toolbar[0].type = 'link'
      } else {
        this.actions.toolbar = []
      }
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      })
      this.getSetting()
    },
    computed: {
      ...mapGetters({
        isMobile: 'isMobile'
      }),
      getCompanyProfile() {
        let company = {
          avatar: this.club.avatar ? getAvatarUrl(this.club.avatar) : require('@/assets/images/no-image.png'),
          name: this.club.name,
          importantData: [],
          details: [],
        }

        if (this.club.whatsAppNumber) {
          company.importantData.push({
            value: this.club.supportNumber || '',
            valueClass: this.$vs.rtl ? 'rtl' : 'ltr',
            key: this.$t('setting.asiderAvatar.support')
          })
        }

        if (this.club.supportNumber) {
          company.importantData.push({
            value: this.club.whatsAppNumber || '',
            valueClass: this.$vs.rtl ? 'rtl' : 'ltr',
            key: this.$t('setting.asiderAvatar.whatsApp')
          })
        }

        return company
      }
    },
    methods: {
      getSetting() {
        getSetting().then((response) => {
          const club = response.data.data.club
          const app = response.data.data.app
          const faq = app.faq ? JSON.parse(app.faq) : {}
          this.club.name = club.club_name
          this.club.avatar = club.club_avatar || ''
          this.club.faq = faq.questions || ''
          this.club.rules.value = app.rules || ''
          this.club.supportNumber = club.support_number || ''
          this.club.whatsAppNumber = club.whatsapp_number || ''
          this.club.address = club.address || ''

          const clubInfo = {
            clubName: this.club.name,
            clubLogo: getAvatarUrl(this.club.avatar)
          }
          this.$store.dispatch('setting/setClubInfo', clubInfo)
        })

      },
      handleChangeProfileStatus (status) {
        if (this.profileStatus !== status) {
          this.profileStatus = ''
          this.$nextTick(() => {
            this.profileStatus = status
          })
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss">
  .setting-menu-page {

    .vx-card__body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
</style>
