<template>
  <div class="employee-list">
    <draggable-dynamic-table ref="cashBoxesOpeningBalanceList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:deleted="selectDeletedRow = $event, handleClick('')"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getEmployeesList"
                             v-model="data"/>


    <!-- show insert employee prompt -->
    <vs-prompt
        class="prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="showInsertEmployeePromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('insertManagerBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
            <!--            <div class="ml-2" @click="handleClick('saveFaq')">-->
            <!--              <custom-icon icon="SAVE" color="success"/>-->
            <!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            افزودن مدیر
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showInsertEmployeePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-employee @inserted="reloadEmployeeList"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <vs-button v-show="false"
               id="insertEmployeeBTN"
               @click="showInsertEmployeePromptStatus = true"/>
  </div>
</template>

<script>
import axios from "axios";
import CustomIcon from "../../../../../components/customIcon/customIcon";
import InsertEmployee from "../insert/insertEmployee2";
import {getUsers} from "../../../../../http/requests/users/users";

export default {
  name: "employeeList",
  components: {InsertEmployee, CustomIcon},
  data() {
    return {
      selectDeletedRow: '',
      showInsertEmployeePromptStatus: false,
      page: 1,
      filters: {roleId: '2'},
      sorts: ['order[0]=created_at,desc'],
      selectedUser: {},
      endedList: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'registryDate',
          headerName: 'تاریخ عضویت',
          width: 'calc(100% / 10)',
          minWidth: 225,
          align: 'center',
          filter: true,
          filterType: 'date',
          sortable: true
          // locked: true
        },
        {
          field: 'phoneNumber',
          i18n: 'users.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 200,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.table.header.name',
          width: 'calc((100% / 10) * 2)',
          minWidth: 250,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'gender',
          headerName: 'جنسیت',
          width: 'calc(100% / 10)',
          minWidth: 100,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: 'همه',
              value: 0
            },
            {
              label: 'آقا',
              value: 1
            },
            {
              label: 'خانم',
              value: 2
            }
          ]
        },
        {
          field: 'row',
          i18n: 'users.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
    }
  },

  created() {
    this.getEmployeesList()
  },

  methods: {
    getEmployeesList() {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.usersListTable && this.data.length === 0) {
            this.$refs.usersListTable.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.usersListTable && this.data.length > 0) {
            this.$refs.usersListTable.loadMoreStatus = 'Loading'
          }

          getUsers(this.page, this.filters, this.sorts).then((res) => {
            this.endedList = res.data.data.length === 0
            res.data.data.forEach((user) => {
              let gender = user.gender === 1 ? 'آقای' : 'خانم'
              this.data.push({
                row: this.data.length + 1,
                id: user.id,
                registryDate: user.created_at,
                gender: user.gender === 1 ? 'آقا' : 'خانم',
                phoneNumber: {
                  value: user.phone_number ? user.phone_number : '',
                  classes: this.$vs.rtl ? 'rtl' : 'ltr'
                },
                firstName: user.name || '',
                lastName: user.family || '',
                name: `${user.name} ${user.family}`,
                // company: user.company ? user.company : '',
                // image: {
                //     type: 'img',
                //     src: user.avatar ? getAvatarUrl(user.avatar) : 'http://irtns.ir/statics/images/user-avatar.jpg',
                //     class: 'product-image',
                //     style: 'height: 30px;'
                // },
                nationalCode: user.national_code ? user.national_code : '',
                // city: `${user.province.name} / ${user.city.name}`,
                // email: user.email ? user.email : ''
              })
            })

            this.page = res.data.pagination.current_page + 1
            if (res.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('row')
              this.columnsLabel[row_index].footer.value = res.data.pagination.total
            }

            if (this.$refs.usersListTable) this.$refs.usersListTable.loadMoreStatus = ''
          })
              .catch((error) => {
                if (this.$refs.usersListTable && !axios.isCancel(error)) this.$refs.usersListTable.loadMoreStatus = 'Danger'
              })
        }
      }, 400)
    },

    setFilters(filters) {
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'accessGroup':
            if (filters[key].search !== '') filters_list.role = `${filters[key].search}`
            break

          case 'gender':
            if (filters[key].search.value > 0) filters_list.gender = `${filters[key].search.value}`
            break

          case 'phoneNumber':
            if (filters[key].search !== '') filters_list.phone_number = `${filters[key].search}`
            break

          case 'company':
            if (filters[key].search !== '') filters_list.company = `${filters[key].search}`
            break

          case 'name':
            if (filters[key].search !== '') filters_list.full_name = `${filters[key].search}`
            break

          case 'registryDate':
            if (filters[key].search !== '') filters_list.created_at = `${filters[key].search}`
            break
        }
      })
      filters_list.roleId = '2'

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getEmployeesList()
    },
    setSort(columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

          case 'row':
            sorts_list.push(`order[0]=id,${columns[key] ? 'desc' : 'asc'}`)
            break

            /*case "accessGroup":
              sorts_list.push("order[0]=role," + (columns[key] ? 'desc' : 'asc'))
              break;*/

          case 'maxDebt':
            sorts_list.push(`order[0]=max_debt,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'balance':
            sorts_list.push(`order[0]=virtual_balance,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'phoneNumber':
            sorts_list.push(`order[0]=phone_number,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'company':
            sorts_list.push(`order[0]=company,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'name':
            sorts_list.push(`order[0]=name,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'registryDate':
            sorts_list.push(`order[0]=created_at,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'group':
            sorts_list.push(`order[0]=group,${columns[key] ? 'desc' : 'asc'}`)
            break

          case 'gender':
            sorts_list.push(`order[0]=gender,${columns[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getEmployeesList()
    },
    reloadEmployeeList() {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getEmployeesList()
      this.showInsertEmployeePromptStatus = false
    },
    handleRowClicked(row) {
    },
    handleClick(id) {
      document.getElementById(id).click()
    },
  }
}
</script>

<style lang="scss" scoped>
.employee-list {
  height: 100%;
}
</style>
