<template>
  <div class="time-setting">
    <div class="time-setting-fields pt-3">
      <custom-select :label="$t('times.labels.timeLength')"
                     :classes="['w-full']"
                     :default="club.timeLength"
                     :options="timeLengthOptions"
                     @input="changeTimeRange"
                     v-model="club.timeLength"
      />

      <div class="time-picker">
        <div class="date-picker-item">
          <custom-select :label="$t('times.labels.startTime')"
                         :classes="[{'w-full': true}]"
                         :options="generateTimeRange"
                         :default="club.startTime"
                         @input="club.endTime = {value: '', label: '-'}"
                         v-model="club.startTime" />
        </div>

        <div class="date-picker-item">
          <custom-select :label="$t('times.labels.endTime')"
                         :classes="[{'w-full': true}]"
                         :options="generateEndTimeOptions"
                         :default="club.endTime"
                         :disabled="club.startTime.label === '-'"
                         v-model="club.endTime" />
        </div>
      </div>
    </div>

    <vs-button v-show="false"
               id="editTimeSettingBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from 'moment'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomSelect from "../../../../../components/customSelect/customSelect.vue";
  import CustomValidateTextarea from "../../../../../components/customInput/customValidateTextarea.vue";
  import CustomValidateInput from "../../../../../components/customInput/customValidateInput.vue";
  import CustomNumberInput from "../../../../../components/customInput/customNumberInput.vue";
  import {editClubSetting} from '../../../../../http/requests/settings/setting'
  import {getClubRequirements} from "@/http/requests/requirements";

  export default {
    name: "insertTime",
    components: {CustomNumberInput, CustomValidateInput, CustomValidateTextarea, CustomSelect, DatePicker},
    data() {
      return {
        timeRegex: this.$validator('regex.time'),
        timeLengthOptions: [
          {
            id: 1,
            label: `30 ${this.$t('times.labels.minute')}`,
            value: '30'
          },
          {
            id: 2,
            label: `60 ${this.$t('times.labels.minute')}`,
            value: '60'
          },
          {
            id: 3,
            label: `90 ${this.$t('times.labels.minute')}`,
            value: '90'
          }
        ],
        club: {
          timeLength: {
            id: 1,
            label: `30 ${this.$t('times.labels.minute')}`,
            value: '30',
            isValid: true
          },
          startTime: {
            value: '',
            label: '-',
            isValid: true
          },
          endTime: {
            value: '',
            label: '-',
            isValid: true
          }
        }
      }
    },

    computed: {
      getStartTimeMax () {
        if (this.club.endTime.value !== '00:00' && this.club.endTime.value !== '24:00') {
          return this.club.endTime.value
        } else {
          return ''
        }
      },
      generateTimeRange () {
        // Take a starting point
        const start = moment('00:00', 'HH:mm');

        // Take a end point
        const end = moment('23:59', 'HH:mm');
        const timeSeries = [{
          label: '00:00',
          value: '00:00'
        }];

        while (start.isSameOrBefore(end)) {
          // add time length minutes to the starting point
          const time = start.add(this.club.timeLength.value, 'm').format('HH:mm')
          timeSeries.push({label: time, value: time});
        }
        return timeSeries
      },
      generateEndTimeOptions () {
        const startTimeIndex = this.generateTimeRange.map(elm => elm.value).indexOf(this.club.startTime.value)
        return this.generateTimeRange.slice(startTimeIndex + 1)
      }
    },

    created() {
      this.getTimeRequirements()
    },

    methods: {
      getTimeRequirements() {
        getClubRequirements().then((response) => {
          const club = response.data.data
          this.club.timeLength.value = club.time_length || ''
          this.club.timeLength.label = club.time_length ? `${club.time_length} ${this.$t('times.labels.minute')} ` : '-'
          this.club.startTime = {
            value: club.club_start_time || '',
            label: club.club_start_time || '-'
          }
          this.club.endTime = {
            value: club.club_end_time === '24:00' ? '00:00' : club.club_end_time || '',
            label: club.club_end_time === '24:00' ? '00:00' : club.club_end_time || '-'
          }
        })
      },

      changeTimeRange () {
        this.club.startTime = {
          value: '',
          label: '-'
        }
        this.club.endTime = {
          value: '',
          label: '-'
        }
      },

      sendData() {
        if (this.club.timeLength.value) {
          const payload = {
            time_length: this.club.timeLength.value,
            club_start_time: this.club.startTime.value,
            club_end_time: this.club.endTime.value === '00:00' ? '24:00' : this.club.endTime.value
          }
          editClubSetting(payload).then(response => {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.message.title'),
              text: this.$t('times.notifications.edit.success'),
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'success'
            })

            this.$emit('edit')
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                time: 2400,
                title: this.$t('alert.error.title'),
                text: error.response.data.message || error.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .time-setting {
    padding: 10px;
    padding-top: 0;
    min-height: 200px;

    .time-setting-fields {
      max-width: 400px;

      .useral-custom-element-select {
        .items {
          max-height: 110px;
        }
      }

      .time-picker {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .date-picker-item {
          border: 0 !important;
          border-radius: 0.4rem;
          position: relative;
          flex-grow: 0.5;
          max-width: 48%;

          .custom-input {
            margin: 0;
          }

          .date-picker-label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 5px;
            padding: 0 5px;
            background-color: #ffffff;
            z-index: 1;
          }

          .vpd-input-group {
            width: 20px !important;
            position: absolute;
            top: 6px;
            left: 2px;

            .vpd-icon-btn {
              opacity: 1 !important;
              height: 25px !important;
              width: 20px !important;
              background-color: inherit !important;

              svg {
                fill: #000000;
                overflow: visible !important;
              }
            }

            .form-control {
              border: 0;
              width: 100%;
              display: none;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
</style>
