<template>
    <div class="address-setting">


        <custom-phone-number-input :classes="{'w-full': true, 'mb-base': true}"
                                   align="right"
                                   :label-placeholder="$t('setting.tel.labels.support')"
                                   :mobile-only="false"
                                   v-model="club.telSupport"/>

        <custom-phone-number-input :classes="{'w-full': true, 'mb-base': true}"
                                   align="right"
                                   :label-placeholder="$t('setting.tel.labels.whatsApp')"
                                   :mobile-only="false"
                                   :regex="whatsAppRegex"
                                   :number-count="12"
                                   v-model="club.whatsAppSupport"/>

<!--        <custom-phone-number-input :classes="{'w-full': true, 'mb-base': true}"-->
<!--                                   align="right"-->
<!--                                   :label-placeholder="$t('setting.tel.labels.sale')"-->
<!--                                   :mobile-only="false"-->
<!--                                   v-model="club.saleSupport"/>-->

        <vs-button v-show="false"
                   id="editTelSettingBTN"
                   @click="sendData"/>
    </div>
</template>

<script>
import axios from "axios"
import { getClubSetting, editClubSetting } from '../../../../http/requests/settings/setting'
import CustomPhoneNumberInput from "../../../../components/customInput/customPhoneNumberInput";
export default {
    name: "telSetting",
    components: {CustomPhoneNumberInput},
    data () {
        return {
            whatsAppRegex: this.$validator('regex.setting.tell.whatsApp'),
            club: {
                telSupport: {
                    value: '',
                    isValid: true
                },
                whatsAppSupport: {
                    value: this.$t('whatsAppPreNumber'),
                    isValid: true
                },
                saleSupport: {
                    value: '',
                    isValid: true
                }
            }
        }
    },

    created () {
        this.getSetting()
    },

    methods: {
        getSetting () {
            getClubSetting().then((response) => {
                const club = response.data.data
                this.club.telSupport.value = club.support_number || ''
                this.club.whatsAppSupport.value = club.whatsapp_number || this.$t('whatsAppPreNumber')
            })

        },

        sendData () {
            if (this.club.telSupport.isValid &&
                this.club.whatsAppSupport.isValid) {
                const payload = {
                    support_number: this.club.telSupport.value,
                    whatsapp_number: this.club.whatsAppSupport.value
                }
                editClubSetting(payload).then(response => {
                    this.$vs.notify({
                        time: 2400,
                        title: this.$t('alert.message.title'),
                        text: this.$t('setting.notifications.editTel.success'),
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'success'
                    })

                    this.$emit('edit')
                }).catch(error => {
                    if (axios.isCancel(error)) {
                        this.$vs.notify({
                            title: this.$t('alert.duplicateRequest.title'),
                            text: this.$t('alert.duplicateRequest.message'),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 2400,
                            color: 'warning'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2400,
                            title: this.$t('alert.error.title'),
                            text: error.response.data.message || error.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .address-setting {
        padding: 0 10px;
    }
</style>
